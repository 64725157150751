<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row>
            <b-col cols="12" xl="8" md="8">
                <b-card no-body class="invoice-actions">
                    <b-card-header>
                        <b-form-checkbox                            
                            v-model="onlyTotals"
                            class="custom-control-primary">
                            Mostrar solo los totales
                            </b-form-checkbox>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="2">
                                <b-form-group label="Fecha Inicio" label-for="date_start">
                                    <flat-pickr v-model="filter.date_start" id="date_start"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="2">
                                <b-form-group label="Fecha Fin" label-for="date_start">
                                    <flat-pickr v-model="filter.date_end" id="date_end"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group label="Caja" label-for="caja_id">
                                    <v-select v-model="filter.caja_Id" id="caja_id" :options="cajas" class="select-size-sm"
                                        placeholder="Selecciona una caja" label="nombre" :reduce="option => option.caja_Id"
                                        :clearable="false"
                                        :searchable="true" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4" class="mt-2">
                                <b-button :disabled="loading" @click="getSales" size="sm"
                                    variant="primary" class="mr-1">
                                    <feather-icon icon="SearchIcon" /> Buscar
                                </b-button>

                                <b-button size="sm" v-if="sales && sales.data" @click="printReport" variant="secondary"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                    <feather-icon icon="DownloadIcon" />
                                    Descargar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="!loading">
            <b-col cols="12" md="12">
                <b-card no-body>
                    <table id="tableMasterDetail" class="table table-bordered table-striped table-sm small text-small text-small">
                        <tbody v-for="(formas, categoria) in sales.data" :key="categoria">
                            <tr>                                
                                <td>
                                    <!-- Título de la categoría -->
                                    <b-card-title>
                                        <h4 class="m-2">{{ categoria }}</h4>
                                    </b-card-title>
                                    
                                    <div v-if="!onlyTotals">
                                        <div v-for="(ventas, forma, idxForm) in formas" :key="forma" class="mb-2 position-relative b-table-no-border-collapse b-table-responsive mb-0 text-small small"
                                            :class="`${ventas.length > 10 ? 'b-table-sticky-header' : ''}`">
                                            <table ref="salesTable" class="table table-striped table-sm text-small b-table-no-border-collapse small">
                                                <thead class="sticky-header">
                                                    <tr>
                                                        <th :colspan="6 + sales.impuestos.length">{{ forma }}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Caja</th>
                                                        <th>Fecha</th>
                                                        <th>Hora</th>
                                                        <th>Ticket</th>
                                                        <th>Factura</th>
                                                        <th>Importe</th>
                                                        <th v-for="impuesto in sales.impuestos" :key="impuesto.id">{{ impuesto }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="scrollable-tbody">
                                                    <tr v-for="(venta, idxVenta) in ventas" :key="idxVenta">
                                                        <td>{{ venta.caja }}</td>
                                                        <td class="text-capitalize">{{ customDate(venta.fecha, 'DD MMM YYYY') }}</td>
                                                        <td>{{ formatHour(venta.hora) }}</td>
                                                        <td>{{ venta.folio }}</td>
                                                        <td>{{ venta.factura }}</td>
                                                        <td>{{ formatCurrency(venta.importe_Neto) }}</td>
                                                        <!-- Celdas dinámicas para impuestos -->
                                                        <td v-for="impuesto in sales.impuestos" :key="impuesto">
                                                            {{  obtenerImporteImpuesto(venta.impuestosDoctos, impuesto) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot class="sticky-footer">
                                                    <tr>
                                                        <th colspan="5" class="text-end"><strong>Subtotal {{ forma }}</strong></th>
                                                        <th><strong>{{ formatCurrency(sumImporte(ventas)) }}</strong></th>
                                                        <th v-for="impuesto in sales.impuestos" :key="impuesto">
                                                            <strong>{{ formatCurrency(ventas.reduce((acc, item) => acc + (obtenerImporteImpuestoNombre(item.impuestosDoctos, impuesto) || 0), 0)) }}</strong>
                                                        </th>
                                                    </tr>
                                                    <tr v-if="isLastIndex(idxForm, formas)">
                                                        <th colspan="5" style="font-size: 14px;">
                                                            <strong>Subtotal {{ categoria }}</strong>
                                                        </th>
                                                        <th style="font-size: 14px;"><strong>{{ formatCurrency(sumGrandTotal(formas)) }}</strong></th>
                                                        <th style="font-size: 14px;" v-for="impuesto in sales.impuestos" :key="impuesto">
                                                            <strong>{{ formatCurrency(sumGranImpuestos(formas, impuesto)) }}</strong>
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div v-else ref="tableMasterDetail">
                                        <table class="w-100">
                                            <thead>
                                                <tr>
                                                    <th>Forma Cobro</th>
                                                    <th>Importe</th>
                                                    <th v-for="impuesto in sales.impuestos" :key="impuesto">{{ impuesto }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ventas, forma) in formas" :key="forma">
                                                    <td>{{ forma }}</td>
                                                    <td>{{ formatCurrency(sumImporte(ventas)) }}</td>
                                                    <td v-for="impuesto in sales.impuestos" :key="impuesto">
                                                        {{ formatCurrency(ventas.reduce((acc, item) => acc + (obtenerImporteImpuestoNombre(item.impuestosDoctos, impuesto) || 0), 0)) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th style="font-size: 13px;">
                                                        <strong>Subtotal</strong>
                                                    </th>
                                                    <th style="font-size: 13px;"><strong>{{ formatCurrency(sumGrandTotal(formas)) }}</strong></th>
                                                    <th style="font-size: 13px;" v-for="impuesto in sales.impuestos" :key="impuesto">
                                                        <strong>{{ formatCurrency(sumGranImpuestos(formas, impuesto)) }}</strong>
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import moment from 'moment';
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,  BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers';
import * as XLSX from 'xlsx';

import DoctosPvProvider from '@/providers/DoctosPV';
const DoctosPVResource = new DoctosPvProvider();

import CajasProvider from '@/providers/Cajas';
const CajasResource = new CajasProvider();

export default {
    name: 'PointOfSaleCharges',
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,        
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        BTooltip, 
        VBTooltip,

        //
        flatPickr,
        vSelect,
    },
    data() {
        return {
            openCategories: {
                ventasTimbradas: false,
                ventasNoTimbradas: false,
                devolucionesTimbradas: false,
                devolucionesNoTimbradas: false,
                cobrosTimbrados: false,
                cobrosNoTimbrados: false,
            }
        }
    },
    methods: {
        isLastIndex(index, formas) {
          const keys = Object.keys(formas); // Obtener las claves del objeto
            return index === keys.length - 1; // Comprobar si es el último índice
        },
        getRowSpan(formas) {
            return Object.values(formas).reduce((sum, ventas) => sum + ventas.length, 0);
        },
        toggleCategory(category) {
            this.openCategories[category] = !this.openCategories[category];
        },
        isCategoryOpen(category) {
            return this.openCategories[category];
        },
        toggleDetails(venta) {
            // Alternar el estado de detalles en cada venta individual
            this.$set(venta, 'showDetails', !venta.showDetails);
        },
        sumGrandTotal(categories) {
            return Object.values(categories).reduce((acc, group) => {
                return acc + group.reduce((acc, item) => acc + item.importe_Neto, 0);
            }, 0);
        },
        identificarImpuestosDuplicados(categories) {
            const impuestosDuplicados = {};

            Object.values(categories).forEach((group, categoryIndex) => {
                group.forEach((item, itemIndex) => {
                    // Crea un contador para este item específico
                    const impuestoCount = item.impuestosDoctos?.reduce((acc, imp) => {
                        acc[imp.impuesto.nombre] = (acc[imp.impuesto.nombre] || 0) + 1;
                        return acc;
                    }, {});

                    // Revisa cuáles tienen más de 1 ocurrencia
                    for (const [impuesto, count] of Object.entries(impuestoCount || {})) {
                        if (count > 1) {
                            if (!impuestosDuplicados[impuesto]) {
                                impuestosDuplicados[impuesto] = [];
                            }
                            impuestosDuplicados[impuesto].push({
                                categoryIndex,
                                itemIndex,
                                count,
                                detalle: item.impuestosDoctos.filter(imp => imp.impuesto.nombre === impuesto),
                            });
                        }
                    }
                });
            });

            return impuestosDuplicados;
        },
        sumGranImpuestos(categories, impuesto) {
            return Object.values(categories).reduce((acc, group) => {
                return acc + group.reduce((acc, item) => {
                    const impuestoImporte = item.impuestosDoctos?.find((imp) => imp.impuesto.nombre == impuesto);
                    if (impuestoImporte) {
                        return acc + impuestoImporte.importe_Impuesto;
                    }                    
                    return acc
                }, 0);
            }, 0);
        },


        // Suma el importe total de un impuesto específico en todas las ventas
        sumImpuestos(formas) {

            const impuestosTotales = {};
            Object.values(formas).forEach((group) => {
                // Itera sobre los elementos del grupo
                group.forEach((item) => {
                    // Recorre los impuestos del documento
                    item.impuestosDoctos?.forEach(({ impuesto, importe_Impuesto }) => {
                        const { impuesto_Id, nombre } = impuesto;
                        // Inicializa el impuesto si no existe en el acumulador
                        if (!impuestosTotales[impuesto_Id]) {
                            impuestosTotales[impuesto_Id] = { nombre, total: 0 };
                        }
                        // Suma el importe del impuesto (o 0 si es undefined)
                        impuestosTotales[impuesto_Id].total += importe_Impuesto || 0;
                    });
                });
            });

            // Devuelve los impuestos como un array
            return Object.values(impuestosTotales);
        },
        // Suma el importe total de las ventas
        sumImporte(ventas) {
            return ventas.reduce((acc, venta) => acc + venta.importe_Neto, 0);
        },
        //Totales globales
        obtenerGrandTotal(categorias) {
            const grandTotal = {
                importeNeto: 0,
                impuestos: {},
            };

            Object.values(categorias).forEach((formas) => {
                Object.values(formas).forEach((ventas) => {
                    ventas.forEach((venta) => {
                        // Sumar el importe neto
                        grandTotal.importeNeto += venta.importe_Neto || 0;

                        // Sumar los impuestos
                        venta.impuestosDoctos?.forEach(({ impuesto, importe_Impuesto }) => {
                            const { impuesto_Id, nombre } = impuesto;
                            if (!grandTotal.impuestos[impuesto_Id]) {
                                grandTotal.impuestos[impuesto_Id] = { nombre, total: 0 };
                            }
                            grandTotal.impuestos[impuesto_Id].total += importe_Impuesto || 0;
                        });
                    });
                });
            });

            return {
                importeNeto: grandTotal.importeNeto,
                impuestos: Object.values(grandTotal.impuestos),
            };
        },
    },
    setup() {

        const onlyTotals = ref(false);
        const cajas = ref([{
            caja_Id: 0,
            nombre: 'TODAS',
        }]);
        const tableMasterDetail = ref(null);
        const filter = reactive({
            date_start: moment().startOf('month').format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            caja_Id: 0,
        });
        const loading = ref(false);
        const rows = ref([]);
        const stampedSales = ref([]);
        const sales = ref([])
        const refTable = ref(null);

        // Función para obtener impuestos únicos
        const obtenerImpuestosUnicos = (rows) => {
            const impuestosMap = new Map();
            rows.forEach((venta) => {
                venta.impuestosDoctos?.forEach((impuestoInfo) => {
                const { impuesto_Id, nombre } = impuestoInfo.impuesto;
                if (!impuestosMap.has(impuesto_Id)) {
                    impuestosMap.set(impuesto_Id, nombre);
                }
                });
            });
            return _.orderBy(Array.from(impuestosMap, ([impuesto_Id, nombre]) => ({
                impuesto_Id,
                nombre,
            })), 'impuesto_Id', 'asc');
        }

        // Función para obtener el importe de un impuesto específico en una venta
        const obtenerImporteImpuesto = (impuestosDoctos, impto) => {
            const impuesto = impuestosDoctos?.find((imp) => imp.impuesto.nombre == impto);
            return impuesto ? impuesto.importe_Impuesto : 0;
        }

        const obtenerImporteImpuestoNombre = (impuestosDoctos, impto) => {
            const impuesto = impuestosDoctos?.find((imp) => imp.impuesto.nombre == impto);
            return impuesto ? impuesto.importe_Impuesto : 0;
        }

        const calcularSubtotalPorTipoVenta = (ventasPorTipo) => {
            return Object.values(ventasPorTipo).reduce((subtotal, venta) => subtotal + venta.importe_Neto*1, 0);
        };


        const translateIndex = (index) => {
            const translations = {
                ventasTimbradas: 'Ventas Timbradas',
                ventasNoTimbradas: 'Ventas No Timbradas',
                devolucionesTimbradas: 'Devoluciones Timbradas',
                devolucionesNoTimbradas: 'Devoluciones No Timbradas',
                cobrosTimbrados: 'Cobros Timbrados',
                cobrosNoTimbrados: 'Cobros No Timbrados',
            };
            return translations[index];
        }

        const _ventasAgrupadas = computed(() => {
            // Crear estructura de datos agrupados por categoría principal
            const categories = {
                ventasTimbradas: [],
                ventasNoTimbradas: [],
                devolucionesTimbradas: [],
                devolucionesNoTimbradas: [],
                cobrosTimbrados: [],
                cobrosNoTimbrados: [],
            };

            // Clasificar cada venta en su grupo correspondiente
            rows.value.forEach((venta) => {
                const { tipo, es_Cfd, certificado } = venta;
                const esTimbrado = es_Cfd === 'S' && certificado === 'S';

                if (tipo == 'Venta') {
                    esTimbrado ? categories.ventasTimbradas.push(venta) : categories.ventasNoTimbradas.push(venta);
                } else if (tipo == 'Devolucion' || tipo == 'Devolución') {
                    esTimbrado ? categories.devolucionesTimbradas.push(venta) : categories.devolucionesNoTimbradas.push(venta);
                } else if (tipo == 'PagoCxC') {
                    esTimbrado ? categories.cobrosTimbrados.push(venta) : categories.cobrosNoTimbrados.push(venta);
                }
            });

            return categories;
        })

        const ventasAgrupadas = computed(() => {
            // Crear estructura de datos agrupados por categoría principal
            const categories = {
                ventasTimbradas: {},
                ventasNoTimbradas: {},
                devolucionesTimbradas: {},
                devolucionesNoTimbradas: {},
                cobrosTimbrados: {},
                cobrosNoTimbrados: {},
            };

            // Identificar dinámicamente las formas de cobro presentes en los datos
            const formasDeCobroExistentes = new Set();

            // Clasificar cada venta en su grupo correspondiente
            rows.value.forEach((venta) => {
                const { tipo, es_Cfd, certificado, forma_Cobro, forma_Cobro_Id } = venta;
                const esTimbrado = es_Cfd === 'S' && certificado === 'S';

                // Identificar las formas de cobro únicas (considerando el formato completo de la cadena)
                formasDeCobroExistentes.add(forma_Cobro);

                // Definir el grupo principal según el tipo de documento
                let group;
                if (tipo === 'Venta') {
                    group = esTimbrado ? 'ventasTimbradas' : 'ventasNoTimbradas';
                } else if (tipo === 'Devolucion' || tipo === 'Devolución') {
                    group = esTimbrado ? 'devolucionesTimbradas' : 'devolucionesNoTimbradas';
                } else if (tipo === 'PagoCxC') {
                    group = esTimbrado ? 'cobrosTimbrados' : 'cobrosNoTimbrados';
                }

                // Si `group` tiene un valor válido, inicializamos `forma_Cobro_Id` en ese grupo
                if (group && categories[group]) {
                    // Asegurar que la forma de cobro esté inicializada en el grupo
                    if (!categories[group][forma_Cobro]) {
                        categories[group][forma_Cobro] = [];
                    }
                    // Agregar la venta a la lista correspondiente
                    categories[group][forma_Cobro].push(venta);
                }
            });

            // Obtener todas las formas de cobro en un arreglo ordenado alfabéticamente (o como prefieras)
            const formasDeCobro = Array.from(formasDeCobroExistentes).sort();
            console.log(formasDeCobro)

            // Asegurar que las formas de cobro estén ordenadas y añadidas dinámicamente
            Object.keys(categories).forEach((groupKey) => {
                const group = categories[groupKey];
                const orderedGroup = {};

                // Ordenar las ventas dentro de cada grupo según las formas de cobro dinámicas
                formasDeCobro.forEach((forma) => {
                    if (group[forma]) {
                        orderedGroup[forma] = group[forma];
                    }
                });

                categories[groupKey] = orderedGroup;
            });

            return categories;
        })


        const groupedSales = computed(() => {
            
            // 1. Agrupar por "caja" y "forma_Cobro"
            const groupedData = rows.value.reduce((groups, item) => {
                const groupKey = `${item.caja} ${item.forma_Cobro}`;
                if (!groups[groupKey]) {
                    groups[groupKey] = [];
                }
                groups[groupKey].push(item);
                return groups;
            }, {});

            // 2. Función para clasificar y ordenar
            const classifyAndSort = (group) => {
                return group.sort((a, b) => {
                    const aType = a.tipo;
                    const aEsCfd = a.es_Cfd === 'S';
                    const aCertificado = a.certificado === 'S';

                    const bType = b.tipo;
                    const bEsCfd = b.es_Cfd === 'S';
                    const bCertificado = b.certificado === 'S';

                    // Primero las ventas timbradas (es_Cfd = 'S' y certificado = 'S')
                    if (aType === 'Venta' && aEsCfd && aCertificado) return -1;
                    if (bType === 'Venta' && bEsCfd && bCertificado) return 1;

                    // Luego las ventas no timbradas
                    if (aType === 'Venta' && !(aEsCfd && aCertificado)) return -1;
                    if (bType === 'Venta' && !(bEsCfd && bCertificado)) return 1;

                    // Luego las devoluciones timbradas
                    if (aType === 'Devolucion' && aEsCfd && aCertificado) return -1;
                    if (bType === 'Devolucion' && bEsCfd && bCertificado) return 1;

                    // Luego las devoluciones no timbradas
                    if (aType === 'Devolucion' && !(aEsCfd && aCertificado)) return -1;
                    if (bType === 'Devolucion' && !(bEsCfd && bCertificado)) return 1;

                    // Finalmente los cobros timbrados y no timbrados
                    if (aType === 'PagoCxC' && aEsCfd && aCertificado) return -1;
                    if (bType === 'PagoCxC' && bEsCfd && bCertificado) return 1;

                    return 0; // En caso de empate
                });
            };

            // 3. Ordenar cada grupo
            for (const key in groupedData) {
                groupedData[key] = classifyAndSort(groupedData[key]);
            }

            return groupedData
        });

        const printReport = async () => {
            
            loading.value = true;
            const query = {
                fechaIni: filter.date_start,
                fechaFin: filter.date_end,
                cajaId: filter.caja_Id,
                soloSubtotales: onlyTotals.value,
            }
           
            DoctosPVResource.exportPointOfSaleCharges(query).then(({ data }) => {
                // Crear un enlace para la descarga
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Cobros-Punto-Venta-${onlyTotals.value ? "Subtotales" : "Detalles"}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove(); // Eliminar el enlace una vez que no es necesario
                loading.value = false;
            }).catch(err => {
                loading.value = false;
                console.error("Error al descargar el archivo:", err);
            });           
        }

        const getCajas = async () => {
            loading.value = true;

            try {
                const { data } = await CajasResource.index();
                if (data.isSuccesful) {
                    cajas.value = [...cajas.value, ...data.data];
                } else {
                    helper.danger(data.message);
                }                
            } catch (e) {
                helper.handleResponseErrors(e);
            }finally {
                loading.value = false;
            }
        }

        const getSales = async () => {
            loading.value = true;
            sales.value = [];
            const query = {
                fechaIni: filter.date_start,
                fechaFin: filter.date_end,
                cajaId: filter.caja_Id,
            }
            try {

                const { data } = await DoctosPVResource.getPointOfSaleCharges(query);
                if (data.isSuccesful) {
                    sales.value = data;
                } else {
                    helper.danger(data.message);
                }

            }catch(e) {
                console.error(e);
                helper.handleResponseErrors(e)
            }finally {
                loading.value = false;
            }
        }

        onMounted(async () => {
            await getCajas();
        });

        return {
            refTable,
            filter,
            loading,
            rows,
            stampedSales,
            tableMasterDetail,
            sales,
            cajas,
            onlyTotals,

            //
            groupedSales,
            ventasAgrupadas,

            //
            calcularSubtotalPorTipoVenta,
            obtenerImpuestosUnicos,
            obtenerImporteImpuesto,
            obtenerImporteImpuestoNombre,
            translateIndex,
            printReport,
            getSales,
        }
        
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 350px; /*calc(100vh - 150px)!important; /* Altura máxima del 100% de la altura de la ventana menos el espacio ocupado por el navbar y el footer*/
}

/* Fija el encabezado en la parte superior */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

/* Fija el pie de tabla en la parte inferior */
.sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
}

/* Define el contenedor con scroll solo para el tbody */
.scrollable-tbody {
  /* display: block; */
  max-height: 300px; /* Ajusta según lo necesites */
  overflow-y: auto;
}

/* Ajusta el ancho de la tabla para alinearla correctamente */
.table {
  width: 100%;
  border-collapse: collapse;
}

</style>